import React from "react";
import styled from "styled-components";
import MainLayout from "../../components/layouts/MainLayout/MainLayout";


const Statistics = () => {
    return(
        <MainLayout Title="About the initiative">
            Statistics
        </MainLayout>
    );
};

export default Statistics;
