import styled from "styled-components";
import SubTitle from "../../../components/shared/SubTitle";
import Title, { TitleType } from "../../../components/shared/Title";

interface ILineageProps {
  Lineage?: string;
  CC?: string;
}

const Card = styled.div`
  overflow: scroll;
`;

const Lineage = ({ Lineage: lineage, CC: cc }: ILineageProps) => {
  console.log(lineage);
  return lineage || cc ? (
    <>
      <Title Type={TitleType.SectionTitle}>
        Correlation, Courtship & Copulation
      </Title>
      {lineage && (
        <Card>
          <SubTitle>Correlation</SubTitle>
          <img src={"/images/lineage/".concat(lineage)} />
        </Card>
      )}
      {cc && (
        <Card>
          <SubTitle>Courtship & Copulation</SubTitle>
          <img src={"/images/courtship/".concat(cc)} />
        </Card>
      )}
    </>
  ) : null;
};

export default Lineage;
