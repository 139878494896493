import React, { useEffect, useState } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import styled from "styled-components"; 
import MainLayout from "../../components/layouts/MainLayout/MainLayout";
import LeopardProfile from "../leopard-profile/LeopardProfile";
import LeopardList from "./components/LeopardList";
import SearchBox from "./components/SearchBox";
import {
    useMutation,
   useQuery,
   useQueryClient,

 } from 'react-query'
import { getLeopards } from "../../../application/LeopardService";
import Loading from "../../components/shared/Loading";
import { Filter } from "../../../application/models/Leopard";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const ListContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Leopards = () => {
    const queryClient = useQueryClient();
    const [filter, setFilter] = useState( {
        Gender: "",
        Location: ""
    });

    let {isLoading, error, data} = useQuery(["leopards", filter], () => getLeopards(filter));

    const filterLeopards = (filter: Filter) => {
        setFilter(filter);
        queryClient.invalidateQueries(['leopards', filter]);
    };

    return(
        <>
        <MainLayout Title="Leopard Profiles">
            <Container>
                <SearchBox search={filterLeopards}/>
                <ListContainer>
                    {isLoading ? <Loading /> : <LeopardList Items={data}/>}
                </ListContainer>
            </Container>
        </MainLayout>
        </>
    );
};


export default Leopards;