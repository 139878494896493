import React from "react";
import styled from "styled-components";
import MainLayout from "../../components/layouts/MainLayout/MainLayout";
import Map from "../../components/shared/Map";
import Paragraph from "../../components/shared/Paragraph";
import Title, { TitleType } from "../../components/shared/Title";
import KumanaMap from "../../components/shared/KumanaMap";

const BodyContainer = styled.div`
  margin: 20px;
`;

const LocationMapContainer = styled.div`
  margin: 50px 0px 0px 0px;
`;

const KumanaNationalPark = () => {
  let center = {
    lat: 6.581481500776785,
    lng: 81.69768673555942,
  };

  return (
    <MainLayout Title="Kumana National Park">
      <BodyContainer>
        <Paragraph>
          Kumana National Park, once known as Yala East, is located at the
          southeastern corner of Sri Lanka covering a protected area of
          35,664.74ha.
        </Paragraph>
        <Paragraph>
          From south, the park is bordered by the Kumbukkan River which is also
          the far east borders of Yala National park block ii and east is
          covered by the southern part of the eastern coast which runs till
          Panama Village (the last populated settlement in the southeastern
          coast).
        </Paragraph>
        <Paragraph>
          Kumana National Park is situated in the dry zone of Sri Lanka and the
          park has a diverse set of ecosystems. Kumana is more prominently
          featured as a bird sanctuary across the world due to its aquatic bird
          friendly setup such as lagoons and wide swamp lake (Kumana Villu). The
          wetland areas of the park are surrounded by dry zone tropical thorn
          forest. At the edge of the Kumbukkan River, the park is covered with a
          pristine riverine forest complex. Rocky outcrops can be found in
          multiple places across the park & large saline lagoons along the coast
          are often surrounded by extensive plains which provide plenty of
          vegetation. Kumana is particularly flagged as a top destination to
          observe one of Sri Lanka’s rarest birds, the black-necked stork.
        </Paragraph>
        <Paragraph>
          Mangrove swamp known as Kumana Villu offers a perfect nesting ground
          for many species of birds. Swamp Lake is at its finest between May -
          June when many water birds migrate to its mangrove swamps to nest. It
          is known that Kumana is home for about 255 species of birds out of 400
          bird species recorded in Sri Lanka.
        </Paragraph>
        <Paragraph>
          Most of the water bodies in Kumana national park are freshwater ponds.
          One of the largest of them, also only 1.6km from the Kumbukkan River,
          is Kumana Wewa, an ancient tank from 1 AD – 1000 AD. It has a diameter
          of more than one kilometer from the longest end to the other.
          Aluthgama Wewa, Eraminiyan Wewa and Bakme Wewa are some other large
          tanks where freshwater can be found.
        </Paragraph>
        <Paragraph>
          Diverse ecosystem provides finest habitats for Sri Lankan mammalian
          species such as spotted deer, wild boar, elephant, leopard sloth bear,
          golden jackal, sambar, stripe necked mongoose etc. Kumana is also
          famously known as a location where original water buffaloes still
          exist.
        </Paragraph>
        <Paragraph>
          Apart from diverse fauna and flora, Kumana is considered as a site
          where ancient cave inscriptions are discovered which date back to the
          1st and 2nd Centuries BC. Bambaragasthalawa, Bowaththe Galge &
          Kiripokuna ruins are evident examples of ancient settlements within
          the park. Remnants of large tanks are solid proof that the heartland
          of Ruhuna, one of the most important cultivated zones in the first
          millennium, was located within Kumana and Yala perimeters.
        </Paragraph>

        <Paragraph>
          The name “Kumana” is the name of a former village at the national
          park’s southernmost border next to Swamp Lake, bordered by the ocean
          in the southeast and by the Kumbukkan River in the southwest. The main
          entrance (Okanda) of the park is 19 km (by road) distance from the
          river mouth of the Kumbukkan Oya.
        </Paragraph>
        <Paragraph>
          In 1938 Kumana was declared as a Bird Sanctuary by the British
          administration. Again in 1969 it was flagged as a national park and
          named as “Yala East”. Subsequently, it was renamed as “Kumana National
          Park” in 2006.
        </Paragraph>
        <LocationMapContainer>
          <Title Type={TitleType.SectionTitle}>
            Location Map of Kumana National Park
          </Title>
          <KumanaMap />
        </LocationMapContainer>
      </BodyContainer>
    </MainLayout>
  );
};

export default KumanaNationalPark;
