import React from 'react';
import styled from 'styled-components';
import {  Formik } from 'formik';
import { FormField, StyledForm, TextAreaControl } from '../../../components/shared/Form'
import { GridView, Row, Col } from '../../../components/shared/GridView';
import Button, { ButtonType } from '../../../components/shared/Button';
import emailjs from "emailjs-com";
import { AppConstant } from '../../../../app/Constants';
import { useState } from 'react';

const MessageSentSuccessful = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 100px 100px;
    width: 100%;
`;

const ErrorMessage = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 100px 100px;
    width: 100%;
`;

interface IMessageProps {
    backgroundColor: string;
}

const Message = styled.div<IMessageProps>`
    padding: 50px;
    margin-bottom: 20px;
    font-family: Arial, Helvetica, sans-serif;
    background-color: ${props => props.backgroundColor};
    border-radius: 5px;
`;

const ContributeForm = () => {
    let [sent, setSent] = useState(false);
    let [sending, setSending] = useState(false);
    let [error, setError] = useState(false);
    
    return (
        <>
           { (!sent && !error)  && <Formik
                initialValues={{
                    name: "",
                    contactNumber: "",
                    email: "",
                    message: "",
                }}
                onSubmit={async (values) => {
                    setSending(true);
                    emailjs.send(
                        AppConstant.EmailServiceId,
                        AppConstant.ContributeEmailTemplate,
                        values,
                        AppConstant.EmailUserId)
                        .then((result) => {
                            setSent(true);
                            setSending(false);
                            console.log(result.text);
                        }, (error) => {
                            setError(true);
                            setSending(false);
                            console.log(error.text);
                        });
                }}
                >
                <StyledForm>
                    <GridView>
                        <Row>
                            <FormField id="name" name="name" placeholder="Name" />
                        </Row>
                        <Row>
                            <FormField id="contactNumber" name="contactNumber" placeholder="Contact Number" />
                        </Row>
                        <Row>
                            <FormField  id="email" name="email" placeholder="example@example.com" type="email" />
                        </Row>
                        <Row>
                            <TextAreaControl rows={10} id="message" name="message" placeholder="How would you like to Contribute to the study (Mandatory)" />
                        </Row>
                        <Row>
                            <Col><Button Text={sending? "Sending...": "Send"} Type={ButtonType.Submit} /></Col>
                        </Row>
                    </GridView>
                </StyledForm>
            </Formik> }

         { sent &&  <MessageSentSuccessful>
                        <Message backgroundColor="green">Your message sent successfully. Someone responsible will contact you soon.</Message>
                        <Button Text="Back" Type={ButtonType.Primary} OnClick={() => setSent(false)} />
                    </MessageSentSuccessful> }

         { error && <ErrorMessage>
                        <Message backgroundColor="red">Error sending message. Please try again later or use kumanaleopards@gmail.com contact us. 
                        </Message> 
                        <Button Text="Back" Type={ButtonType.Primary} OnClick={() => setError(false)} />
                    </ErrorMessage>}
        </>
    );
};

export default ContributeForm;

