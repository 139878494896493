import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
`;

const Title = styled.div`
    width: 40%;
    height: auto;
    display:flex;
    justify-content: center;
    align-items:center;
    background-color: #9fb49d;
    font-weight: bold;
    //font-size: 35px;
    @media (min-width: 800px) {
        font-size: 35px;
    };
    @media (max-width: 799px) {
        font-size: 20px;
    };
    @media (max-width: 400px) {
        font-size: 14px;
    };
`;
const Image = styled.img`
    width: 60%;
`;

const GalleryLink = styled(Link)`
    text-decoration: none;
    color: #000;
`;

const HomePhotoGallery = () => {
    return(
        <Container>
            <Title><GalleryLink to="/gallery">Photo Gallery</GalleryLink></Title>
            <Image src="/images/gallery-banner.jpg" />
        </Container>
    );
};

export default HomePhotoGallery;