import React from "react";
import styled from "styled-components";

export enum ButtonType {
    Primary,
    Submit
}

const StyledButton = styled.button`
    background-color: #9fb49d;
    border: none;
    border-radius: 10px;
    color: white;
    padding: 16px 32px;
    text-decoration: none;
    margin: 10px 10px;
    cursor: pointer;
    font-size: 18px;
    width: 20%;
`;

interface IButtonProps {
    Type: ButtonType;
    Text: string;
    OnClick?: () => void;
}

const Button = ({Type: type, Text: text, OnClick}: IButtonProps) => {

    const getButton = () => {
        switch(type) {
            case ButtonType.Primary: return <StyledButton onClick={OnClick} >{text}</StyledButton>;
            case ButtonType.Submit: return <StyledButton type="submit" >{text}</StyledButton>;
            default:  return <StyledButton onClick={OnClick} >{text}</StyledButton>;
        }
    };

    return(
        <>
        {
            getButton()
        }
        </>
    );
};

export default Button;
