import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

interface IMenuItemProps {
    LinkTo: string;
    Color?: string;
    children: any;
}

const MenuItemContainer = styled.li`
    display: block;
    margin: 0px 10px;
`;

const MenuLink = styled(Link)`
    color: ${props => props.color || "#fff"};
    text-decoration: none;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 14px;
`;

const MenuItem = (props: IMenuItemProps) => {
    return(
        <MenuItemContainer>
            <MenuLink to={props.LinkTo} color={props.Color}>{props.children}</MenuLink>
        </MenuItemContainer>
    );
};

export default MenuItem;