import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Title, {TitleType} from "./Title";

const Container = styled.div`
width: 100%;
margin: 20px;
`;
const FooterLinks = styled.div`
    
`;

const FooterLink = styled(Link)`
    text-decoration: none;
    color: #000;
    font-weight: bold;
    padding-right: 20px;
`;

const Disclaimer = styled.div`
    font-style: italic;
    font-family: Arial, Helvetica, sans-serif;
`;

const DisclaimerContainer = styled.div`
    margin: 50px 0px;
    padding: 0px 20px 0px 0px;
`;


const Footer = () => {
    return(
        <Container>
            <FooterLinks>
                <FooterLink to="credits-copyrights">Credits & Copyrights</FooterLink>
                <FooterLink to="contact-us">Contact Us</FooterLink>
            </FooterLinks>
            <DisclaimerContainer>
                <Title Type={TitleType.SubTitle} BackgroundColor="transparent" Padding="0px">Disclaimer</Title>
                <Disclaimer>                
                    This study is not considered as scientific research and is a study based on data collation on sightings and conducts observed by regular visitors and park officials. None of the published data were collected through camera traps or using any other remote devices.
                </Disclaimer>
            </DisclaimerContainer>
            
        </Container>
    );
};

export default Footer;