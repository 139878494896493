import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import Title, { TitleType } from "../../../components/shared/Title";
import Map, { Position } from "../../../components/shared/Map";
import { getLatestSightings } from "../../../../application/SightingService";
import { getLeopards } from "../../../../application/LeopardService";
import { LeopardItem } from "../../../../application/models/Leopard";

const SightingContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const MapContainer = styled.div`
  width: 100%;
`;

const SightingDistribution = () => {
  const [markers, setMarkers] = useState([]);
  const [leopards, setLeopards] = useState<LeopardItem[]>([]);
  const center = {
    lat: 6.581481500776785,
    lng: 81.69768673555942,
  };

  const getLeopardList = useCallback(() => {
    getLeopards({ Location: "", Gender: "" }).then((res) => {
      setLeopards(res);
    });
  }, []);

  useEffect(() => {
    getLeopardList();
  }, []);

  useEffect(() => {
    if (leopards.length > 0) {
      getLatestSightings(200).then((res) => {
        let markers = res.map((s) => ({
          id: s.id,
          thumbnail: "/images/pug-icon.png",
          leopardId: s.leopardId,
          title: s.date.concat(" ", s.time),
          position: { lat: Number(s.lat), lng: Number(s.lang) } as Position,
          sighting: { ...s, name: getLeopardName(s.leopardId) },
        }));

        setMarkers(markers);
      });
    }
  }, [leopards]);

  function getLeopardName(id) {
    const leopard = leopards.find((l) => l.id == id);
    return leopard ? leopard.name : "";
  }

  return (
    <SightingContainer>
      <Title Type={TitleType.SectionTitle}>Sighting Distribution</Title>
      <MapContainer>
        <Map
          zoom={13}
          height="800px"
          center={center}
          markers={markers}
          hideMarkerLabel={true}
        />
      </MapContainer>
    </SightingContainer>
  );
};

export default SightingDistribution;
