import React from "react";
import styled from "styled-components";

const Paragraph = styled.p`
    margin-top: 30px;
    font-size: 16px;
    text-align: justify;
    text-justify: inter-word;
    font-family: Arial, Helvetica, sans-serif;
`;

export default Paragraph;