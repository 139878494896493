import { useEffect, useState } from "react";
import styled from "styled-components";
import { AppConstant } from "../../../app/Constants";
import { Sighting } from "../../../application/models/Sighting";

interface IInfoWindowProps {
  sighting: Sighting;
}

const InfoCard = styled.div`
  display: flex;
  width: 150px;
  flex-direction: column;
`;

const InfoCardTitle = styled.div`
  font-weight: bold;
  padding-bottom: 10px;
`;

const Thumbnail = styled.img`
  width: 150px;
  hight: auto;
`;

const Details = styled.div`
  font-family: Arial, Helvetica, sans-serif;
  font-size: 10px;
  display: flex;
  flex-direction: column;
  margin-top: 5px;
`;

const DetailItem = styled.div``;

const MapInfoWindow = ({ sighting }: IInfoWindowProps) => {
  let [thumb, setThumb] = useState("");

  useEffect(() => {
    setThumb(AppConstant.ThumbnailBaseUrl.concat("/", sighting.thumbnail));
  }, [sighting]);

  return (
    <InfoCard>
      <InfoCardTitle>
        {sighting.leopardId} {sighting.name && `- ${sighting.name}`}
      </InfoCardTitle>
      <Thumbnail src={thumb} />
      <Details>
        <DetailItem>
          {sighting.date} {sighting.time}
        </DetailItem>
        <DetailItem>{sighting.observation}</DetailItem>
        <DetailItem>{sighting.sightedBy}</DetailItem>
      </Details>
    </InfoCard>
  );
};

export default MapInfoWindow;
