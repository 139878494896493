import React from "react";
import styled from "styled-components";
import Copyright from "../../shared/Copyright";
import MainContainer from "../../shared/MainContainer";
import Header from "./components/Header";

const LayoutContainer = styled.div`
    display:flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
`;

const HeaderContainer = styled.div`
    display:flex;
    width: 100%;
`;

const BodyContainer = styled.div`
    display: flex;
    width: 100%;
`;

const FooterContainer = styled.div`
    display: flex;
    width: 100%;
`;

interface ILayoutProps {
    Title?: string;
    children?: any;
}

const MainLayout = ({Title, children}: ILayoutProps) => {
    return(
        <>
        <MainContainer>
            <LayoutContainer>
                <HeaderContainer>
                    <Header Title={Title}/>
                </HeaderContainer>
                <BodyContainer>
                    {children}
                </BodyContainer>
            </LayoutContainer>
        </MainContainer>
        <FooterContainer>
            <Copyright />
        </FooterContainer>
        </>
    );
};

export default MainLayout;