import { AppConstant } from "../app/Constants";
import { http } from "./BaseService";
import { LatestSighting, Sighting } from "./models/Sighting";

export async function getLatestSightings(number) {
  return await http<LatestSighting[]>(
    AppConstant.BaseUrl.concat("/api/v1/sighting/latest/").concat(number)
  );
}

export async function getSightings(leopardId) {
  return await http<Sighting[]>(
    AppConstant.BaseUrl.concat("/api/v1/sighting/").concat(leopardId)
  );
}

export async function getContributions() {
  return await http<string[]>(
    AppConstant.BaseUrl.concat("/api/v1/contribution")
  );
}

export async function getSightingLocations() {
  return await http<string[]>(
    AppConstant.BaseUrl.concat("/api/v1/sightingLocations")
  );
}
