import styled from "styled-components";

export const GridView = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`;

interface IColProps {
    Width?: string;
}

export const Col = styled.div<IColProps>`
    display: flex;
    flex-direction: column;
    width: ${props => props.Width ? props.Width : "100%"};
`;