import styled from "styled-components";
import MainLayout from "../../components/layouts/MainLayout/MainLayout";
import ContactForm from "./components/ContactForm";

const BodyContainer = styled.div`
    margin: 20px;
    width: 100%;
`;

const ContactUs = () => {
    return(
        <MainLayout Title="Contact Us">
            <BodyContainer>
                <ContactForm />
            </BodyContainer>
        </MainLayout>
    );
};

export default ContactUs;