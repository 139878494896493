import React from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import Home from "../views/pages/home/Home";
import KumanaNationalPark from "../views/pages/kumana/KumanaNationalPark";
import AdminContainer from "../views/pages/admin/AdminContainer";
import Leopards from "../views/pages/leopards/Leopards";
import LeopardProfile from "../views/pages/leopard-profile/LeopardProfile";
import About from "../views/pages/about/About";
import CorelationData from "../views/pages/corelation-data/CorelationData";
import Statistics from "../views/pages/statistics/Statistics";
import Contribute from "../views/pages/contribute/Contribute";
import ContactUs from "../views/pages/contact/ContactUs";
import Gallery from "../views/pages/gallery/gallery";
import Copyright from "../views/pages/copyright/Copyright";
import LeopardMap from "../views/pages/lopard-map/LeopardMap";

const AppContainer = () => {
    return(
        <Router>
            <Switch>
                <Route exact path="/"> <Home /> </Route>
                <Route exact path="/kumana-national-park"><KumanaNationalPark /></Route>
                <Route exact path="/leopard-profiles"><Leopards /></Route>
                <Route path={"/leopard-profiles/:id"} ><LeopardProfile /></Route>
                <Route exact path="/about"><About /></Route>
                <Route exact path="/corelation-data"><CorelationData /></Route>
                <Route exact path="/statistics"><Statistics /></Route>
                <Route exact path="/gallery"><Gallery /></Route>
                <Route exact path="/contribute"><Contribute /></Route>
                <Route exact path="/contact-us"><ContactUs /></Route>
                <Route exact path="/credits-copyrights"><Copyright /></Route>
                <Route exact path="/distribution-map"><LeopardMap /></Route>
                <Route path="/admin" component={AdminContainer} ></Route>
                <Redirect to="/" />
            </Switch>
        </Router>
    );
};

export default AppContainer;


