import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { getLatestSightings } from "../../../../application/SightingService";
import Title, { TitleType } from "../../../components/shared/Title";
import SightingItem from "./SightingItem";
import { LeopardItem } from "../../../../application/models/Leopard";
import { getLeopards } from "../../../../application/LeopardService";
import { LatestSighting } from "../../../../application/models/Sighting";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Sightings = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  list-style-type: none;
  margin: 0;
  padding: 0;
  flex-wrap: wrap;
`;

const LatestSightings = () => {
  const [sightings, setSightings] = useState<LatestSighting[]>([]);
  const [leopards, setLeopards] = useState<LeopardItem[]>([]);

  const getLeopardList = useCallback(() => {
    getLeopards({ Location: "", Gender: "" }).then((res) => {
      setLeopards(res);
    });
  }, []);

  useEffect(() => {
    getLeopardList();
  }, []);

  useEffect(() => {
    if (leopards.length > 0) {
      getLatestSightings(5).then((res) => {
        const sightingList = res.map((s) => ({
          ...s,
          name: getLeopardName(s.leopardId),
        }));
        setSightings(sightingList);
      });
    }
  }, [leopards]);

  function getLeopardName(id) {
    const leopard = leopards.find((l) => l.id == id);
    return leopard ? leopard.name : "";
  }
  return (
    <Container>
      <Title Type={TitleType.SectionTitle}>Latest Sightings</Title>
      <Sightings>
        {sightings.map((a) => (
          <SightingItem key={a.id} Item={a} />
        ))}
      </Sightings>
    </Container>
  );
};

export default LatestSightings;
