import styled from "styled-components";
import EmailLink from "./EmailLink";

const CopyrightContainer = styled.div`
    background-color: #9fb49d;
    font-size: 12px;
    text-align: center;
    width: 100%;
    margin: 0px 2px;
    padding: 10px 0px;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
`;

const Copyright = () => {
    const year = new Date().getFullYear();
    return (
        <CopyrightContainer>
            Copyright © {year} Leopards of Kumana <EmailLink MailTo="kumanaleopards@gmail.com" />
        </CopyrightContainer>
    );
};

export default Copyright;