import styled from "styled-components";
import MainLayout from "../../components/layouts/MainLayout/MainLayout";
import { useQuery } from "react-query";
import { getGallery } from "../../../application/GalleryService";
import ImageGallery, { GalleryImage } from "../../components/shared/ImageGallery";
import { AppConstant } from "../../../app/Constants";

const ImageGalleryContainer = styled.div`
    height: 900px;
    min-height: 700px;
    padding: 20px;
`;

const Gallery = () => {

    let {isLoading, error, data} = useQuery(["gallery"], () => getGallery());

    let getImagesMapped = (): GalleryImage[] => {
        let images = data.map(d => (
            {
                src: AppConstant.GalleryImages.concat("/").concat(d.image),
                thumbnail: AppConstant.GalleryImages.concat("/thumbnails/").concat(d.thumbnail),
                alt: d.caption
            } as GalleryImage
            ));

        return images;
    };

    return(<MainLayout Title="Photo Gallery">
            <ImageGalleryContainer>
                {!isLoading && <ImageGallery Images={getImagesMapped()} /> }
            </ImageGalleryContainer>
        </MainLayout>);
};

export default Gallery;