import React from "react";
import styled from "styled-components";

export const Table = styled.table`
  border-collapse: collapse;
  font-family: Arial, Helvetica, sans-serif;
`;

export const TableHead = styled.thead`
  background-color: "#9fb49d";
`;

export const TableFoot = styled.tfoot`
  // custom css goes here
`;

export const TableBody = styled.tbody`
 // custom css goes here
`;

export const TableRow = styled.tr`
  // custom css goes here
   &:hover {background-color: #f5f5f5;}

   &:first-child {
      background-color: #f5f5f5;
      font-weight: bold;
   }
`;

export const TableHeaderColumn = styled.th`
  border-bottom: 1px solid #ddd;
  padding: 10px;
  background-color: "#000";
  text-align: "left";
`;

export const TableCell = styled.td`
  border-bottom: 1px solid #ddd;
  padding: 10px;
`;