import { AppConstant } from "../app/Constants";
import { http } from "./BaseService";
import { LeopardProfile, LeopardItem, Filter } from "./models/Leopard";

export async function getLeopards(filter: Filter) {
   let params = "";
   if ( filter.Location !== "" && filter.Gender !== "") {
      params = params.concat("?gender=", filter.Gender, "&location=", filter.Location);
   } else if (filter.Gender !== "") {
      params = params.concat("?gender=", filter.Gender);
   } else if (filter.Location  !== "") {
       params = params.concat("?location=", filter.Location);
   }

   return await http<LeopardItem[]>(AppConstant.BaseUrl.concat("/api/v1/leopard", params));
}

export async function getLeopardsWitCorelationData() {
   let leopards = await http<LeopardItem[]>(AppConstant.BaseUrl.concat("/api/v1/leopard"));
   let corelation = leopards.filter(l => l.lineage || l.cc);
   return corelation;
}

export async function getLeopard(leopardId: string) {
   return await http<LeopardProfile>(AppConstant.BaseUrl.concat("/api/v1/leopard/").concat(leopardId));
}