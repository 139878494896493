import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import MainLayout from "../../components/layouts/MainLayout/MainLayout";
import Button, { ButtonType } from "../../components/shared/Button";
import EmailLink from "../../components/shared/EmailLink";
import LinkButton from "../../components/shared/LinkButton";
import Paragraph from "../../components/shared/Paragraph";
import SubTitle from "../../components/shared/SubTitle";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderColumn,
  TableRow,
} from "../../components/shared/Table";

const BodyContainer = styled.div`
  margin: 20px;
`;

const ListItem = styled.li`
  margin-top: 20px;
`;

const Disclaimer = styled.div`
  margin: 40px 0px;
  font-style: italic;
  font-family: Arial, Helvetica, sans-serif;
`;

const ZoneContainer = styled.div`
  width: 100%;
`;

const ZoneImage = styled.img`
  width: 100%;
`;

const UnorderedList = styled.ul`
  margin-top: 30px;
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
`;

const DownloadButtonContainer = styled.div`
  padding: 10px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DownloadFieldGuid = styled.div`
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  border-width: 1px 0px 1px 1px;
  border-style: solid;
  padding: 16px 32px;
  text-transform: capitalize;
`;

const About = () => {
  return (
    <MainLayout Title="About the initiative">
      <BodyContainer>
        <Paragraph>
          Kumana National Park has been an incredible stronghold for Sri Lankan
          Leopards (Panthera pardus kotiya). The park adjoins Kudumbigala
          Sanctuary and Yala Block 2, providing further habitats for leopards to
          extend their behavioral landscape.
        </Paragraph>
        <Paragraph>
          While observing behaviors of these elusive animals within Kumana and
          Kudumbigala boundaries, we learned the need and importance of
          identifying individual leopards and continually recording their
          behavior patterns for greater conservation purposes. Having a north
          east boundary bordering farming grounds and human settlements, we
          observed the overlapping co-existence of Human-Leopard activities.
          Seeing regular leopard sightings within and outside park boundaries,
          we initiated this work in identifying leopards and recording their
          behaviors in 2019. This work has specifically helped park officials to
          get a better understanding of the population density as well as to
          study the depth of their individual territories. To make this study
          more meaningful and beneficial to the park, we also involved a few
          park officials in the study.
        </Paragraph>
        <Paragraph>
          The collection of data on identification and behavioral analysis of
          leopards are based on photographic evidence which were / are
          contributed to this study by the wildlife enthusiasts who visit the
          park regularly, park officials and the authors of this study. Leopards
          that were sighted from 2017 were given identification codes.
        </Paragraph>
        <SubTitle>Purpose of this initiative</SubTitle>
        <UnorderedList>
          <ListItem>
            Support park staff with information for conservation efforts and for
            further research work related to these mammals
          </ListItem>
          <ListItem>
            Learn the population density of leopards in Kumana National Park
            through identification
          </ListItem>
          <ListItem>
            Map individual leopard territories and territory movements in
            relation to behavioral patterns
          </ListItem>
          <ListItem>
            Record family trees/correlation data of identified leopards
          </ListItem>
          <ListItem>
            To establish a databank for park visitors to get a better
            understanding and observe leopard activities expressively
          </ListItem>
        </UnorderedList>
        <Disclaimer>
          Disclaimer: This study is not considered as scientific research and is
          a study based on data collation on sightings and conducts observed by
          regular visitors and park officials. None of the published data were
          collected through camera traps or using any other remote devices.
        </Disclaimer>
        <Paragraph>
          This work is voluntarily initiated by Sasika Sri Sanjeewa, Wasantha
          Disanayaka, Thaamara Kariyawasam, Prashanth Jayasekera, Danushka
          Senadheera & Shanaka Kalubowila (Authors of this study).
        </Paragraph>
        <SubTitle>Leopard identification method</SubTitle>
        <Paragraph>
          Individual leopards are being identified by analyzing their rosette
          patterns and spot formations which are unique from one individual to
          another. Identification codes are formed in the following format;
        </Paragraph>
        <Table width="100%">
          <TableBody>
            <TableRow>
              <TableCell>Abbreviation</TableCell>
              <TableCell>Denotation </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>K</TableCell>
              <TableCell>
                Stands as the first letter of the national park name (<b>K</b>
                umana)
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>L</TableCell>
              <TableCell>
                Stands for <b>L</b>eopard
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>M or F</TableCell>
              <TableCell>
                Reflects the Gender (<b>M</b>ale/<b>F</b>emale) of the Leopard
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>"1", "2", "3"....."47", "48", "49"....</TableCell>
              <TableCell>
                Unique sequential number to exclusively identify individuals
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Paragraph>
          <b>E.g.</b>
          <br />
          <br />
          KLM9 - indicates the <b>9</b>th <b>M</b>ale <b>L</b>eopard identified
          in <b>K</b>umana. <br />
          KLF9 - indicates the <b>9</b>th <b>F</b>emale <b>L</b>eopard
          identified in <b>K</b>umana.
          <br />
        </Paragraph>
        <SubTitle>Kumana Leopard offline guide</SubTitle>
        <Paragraph>
          Due to limited availability of mobile reception within the park, park
          staff and visitors may not be able to access this website or Facebook
          page instantly while travelling inside the park to check
          identification or to obtain published data of our study. Therefore the
          below field guide is made available with the purpose of providing fast
          access to spot patterns of each individual leopard and to obtain other
          basic information conveniently while travelling inside the park.
        </Paragraph>
        <Paragraph>
          This field guide will be updated regularly by authors with latest
          findings, where readers will be able to get access to latest data
          while on the field.
        </Paragraph>
        <Paragraph>
          This document can be freely downloaded from the section below by
          anyone who is interested in gaining knowledge about Leopards of
          Kumana. If you would like to extract/export content of this document
          for further references or to obtain more information, kindly write to
          us via: <EmailLink MailTo="kumanaleopards@gmail.com" />
        </Paragraph>

        <DownloadButtonContainer>
          <DownloadFieldGuid>Kumana leopard field guide</DownloadFieldGuid>
          <LinkButton
            target="_blank"
            Type={ButtonType.Primary}
            src="https://kumanaleopards.com/images/leopards-of-kumana-field-guide.pdf"
            Text="Download"
          />
        </DownloadButtonContainer>

        <SubTitle>Leopard population distribution</SubTitle>
        <Paragraph>
          To profoundly analyze data, based on road network limits within the
          park, the area has been virtually segregated into three sections (Zone
          A, Zone B & Zone C).
        </Paragraph>
        <UnorderedList>
          <ListItem>
            Zone A – Okanda Park Entrance to Thummulla Bungalow
          </ListItem>
          <ListItem>
            Zone B – Thummulla Bungalow to Madamethota (Kuda Kabiliththa)
          </ListItem>
          <ListItem>
            Zone C – Panama to Park entrance (Halawa/Kudumbigala)
          </ListItem>
        </UnorderedList>
        <ZoneContainer>
          <ZoneImage src="/images/park-zones.png" />
        </ZoneContainer>
        <SubTitle>Become a Contributor </SubTitle>
        <Paragraph>
          Wildlife enthusiasts are warmly welcome to become contributors of this
          study. All information on leopard sightings/observation and
          photographic evidence will be treated with high regard and individual
          copyright credits will be maintained.
        </Paragraph>
        <Paragraph>
          <Link to="/contribute">Click here</Link> to become a part of this
          study!
        </Paragraph>

        <SubTitle>Contact Us</SubTitle>
        <Paragraph>
          Are your interested to obtain more insights and references of our
          study? Please email us on{" "}
          <EmailLink MailTo="kumanaleopards@gmail.com" /> or{" "}
          <Link to="/contact-us">click here</Link> to submit your inquiry.
        </Paragraph>
        <Paragraph>
          Find us on{" "}
          <a href="https://www.facebook.com/groups/399628524415941/">
            Facebook
          </a>{" "}
          for more updates.
        </Paragraph>
      </BodyContainer>
    </MainLayout>
  );
};

export default About;
