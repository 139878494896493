import React from "react";
import styled from "styled-components";

export enum TitleType {
    MainTitle,
    SectionTitle,
    SubTitle,
}

// tslint:disable-next-line: typedef
const MainTitle = styled.h1`
    color: ${props => props.color || "$fff"};
    font-size: 36px;
    text-transform: uppercase;

    @media (min-width: 1001px) {
       font-size: 36px;
    };
  
    @media (max-width: 1000px) {
        font-size: 24px;
    };

    @media (max-width: 400px) {
        font-size: 18px;
    };
`;

// tslint:disable-next-line: typedef
const SectionTitle = styled.h2`
    color: ${props => props.color || "$fff"};
    font-size: 20px;
    text-transform: uppercase;
`;

// tslint:disable-next-line: typedef
const StyledSubTitle = styled.h3`
    color: ${props => props.color || "$fff"};
    font-size: 18px;
    text-transform: uppercase;
    font-family: Arial, Helvetica, sans-serif;
`;

interface ITitleContainerProps {
    backgroundColor:string;
    padding: string;
}

// tslint:disable-next-line: typedef
const TitleContainer = styled.div<ITitleContainerProps>`
    background-color: ${props => props.backgroundColor || "#9fb49d"};
    padding: ${props => props.padding || "5px 10px"}; ;
`;

interface ITitleProps {
    Type?: TitleType;
    Color?: string;
    BackgroundColor?:string;
    Padding?: string;
    children?: any;
}

// tslint:disable-next-line: typedef
const Title = (props: ITitleProps) => {

    // tslint:disable-next-line: typedef
    const getTitle = (props: ITitleProps) => {
        switch(props.Type) {
            case TitleType.MainTitle: return <MainTitle color={ props.Color }>{props.children}</MainTitle>;
            case TitleType.SectionTitle: return <SectionTitle color={ props.Color }>{props.children}</SectionTitle>;
            case TitleType.SubTitle: return <StyledSubTitle color={ props.Color }>{props.children}</StyledSubTitle>;

            default: return <StyledSubTitle color={ props.Color }>{props.children}</StyledSubTitle>;
        }
    };

    return (
        <TitleContainer backgroundColor={props.BackgroundColor} padding={props.Padding}>
            { getTitle(props) }
        </TitleContainer>
    );
};


export default Title;

