import React from "react";
import styled from "styled-components";

// tslint:disable-next-line: typedef
const StyledSubTitle = styled.h3`
    color:  #666699;
    font-size: 18px;
    text-transform: uppercase;
    font-family: Arial, Helvetica, sans-serif;
    margin: 40px 0px 10px 0px;
`;

const SubTitle = ({children}) => {
    return (
         <StyledSubTitle >
            {children}
         </StyledSubTitle>
    );
};

export default SubTitle;

