import React, { useEffect, useState } from "react";
import styled from "styled-components";
import MainLayout from "../../components/layouts/MainLayout/MainLayout";
import Accordion from "react-bootstrap/Accordion";
import { useQuery } from "react-query";
import { getLeopardsWitCorelationData } from "../../../application/LeopardService";
import { AppConstant } from "../../../app/Constants";
import 'bootstrap/dist/css/bootstrap.min.css';

const CorelationList = styled(Accordion)`
    width: 100%;
    padding: 20px 0px;
`;

const Header = styled.div`
    display: flex;
    width: 100%;
`;

const HeaderTitle = styled.span`
    font-weight: bold;
    flex: 1;
`;

const HeaderDetail = styled.span`
    padding: 0px 20px;
`;

const CardContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
`;

const Card = styled.div`
    display: flex;
    transition: 0.3s;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
`;

const CardTitle = styled.div`
    font-weight: bold;
    padding: 10px;
    background-color: #9fb49d;
`;

const CardContent = styled.img``;

const CorelationData = () => {
    let [corelationData, setCorelationData] = useState([]);
    let [defaultKey, setDefaultKey] = useState("0");

    let {isLoading, error, data} = useQuery("leopards", () => getLeopardsWitCorelationData());

    useEffect(() => {
        setCorelationData(data);

        if (data && data.length > 0) {
            setDefaultKey(data[0].id);
        }
    }, [data]);

    return(
        <MainLayout Title="Corelation Data">
            { !isLoading &&
                corelationData && <CorelationList defaultActiveKey={defaultKey}>
                   {
                        corelationData.map(d => (
                           <Accordion.Item eventKey={d.id}>
                               <Accordion.Header>
                                   <Header>
                                        <HeaderTitle> Leopard id: {d.id}</HeaderTitle>
                                        <HeaderDetail>Gender: {d.gender}, Zone: {d.zone}</HeaderDetail>
                                   </Header>
                               </Accordion.Header>
                               <Accordion.Body>
                                   <CardContainer>
                                        {d.lineage && <Card>
                                           <CardTitle>Lineage</CardTitle>
                                           <CardContent src={AppConstant.LineageImages.concat("/", d.lineage)}></CardContent>
                                       </Card>}
                                       {d.cc && <Card>
                                           <CardTitle>Courtship & Copulation</CardTitle>
                                           <CardContent src={AppConstant.CourtshipImages.concat("/", d.cc)}></CardContent>
                                       </Card>}
                                   </CardContainer>
                               </Accordion.Body>
                           </Accordion.Item>
                       ))
                   }
                </CorelationList>
            }
        </MainLayout>
    );
};

export default CorelationData;