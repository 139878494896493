export const AppConstant = {
    "BaseUrl": process.env.REACT_APP_WEB_ROOT,
    "IndividualImages": "/images/leopards/individuals",
    "GalleryImages": "/images/gallery",
    "LineageImages": "/images/lineage",
    "CourtshipImages": "images/courtship",
    "MapKey": process.env.REACT_APP_MAP_KEY, 
    "ThumbnailBaseUrl": "/images/leopards/thumbnails",
    "EmailUserId": "user_FBM1VNushTHnEJ3Ehber0",
    "ContactEmailTemplate": "template_jtgz3vl",
    "ContributeEmailTemplate": "template_lxejvpn",
    "EmailServiceId": "service_ngqsgiu",
    "MaleColor": "#1975d2",
    "FemaleColor": "#fbc12d",
};

