import React from "react";
import styled from "styled-components";

export enum ButtonType {
    Primary,
    Submit
}

const StyledLink = styled.a`
    background-color: #9fb49d;
    border: none;
    color: white;
    padding: 16px 32px;
    text-decoration: none;
    cursor: pointer;
    font-size: 18px;
`;

interface ILinkButtonProps {
    Type: ButtonType;
    Text: string;
    src: string;
    target: string | undefined
}

const LinkButton = ({Type: type, Text: text, src, target}: ILinkButtonProps) => {

    const getButton = () => {
        switch(type) { 
            case ButtonType.Primary: return <StyledLink href={src} target={target}>{text}</StyledLink>;
            default:  return <StyledLink href={src} target={target}>{text}</StyledLink>;
        }
    };

    return(
        <>
        {
            getButton()
        }
        </>
    );
};

export default LinkButton;
