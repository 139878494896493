import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { AppConstant } from "../../../app/Constants";
import { getLeopard } from "../../../application/LeopardService";
import { getSightings } from "../../../application/SightingService";
import MainLayout from "../../components/layouts/MainLayout/MainLayout";
import ImageGallery, {
  GalleryImage,
} from "../../components/shared/ImageGallery";
import Title, { TitleType } from "../../components/shared/Title";
import SightingDistribution from "../home/components/SightingDistributution";
import IndividualSightingDistribution from "./components/IndividualSightingDistribution";
import Lineage from "./components/Lineage";
import ProfileDetails from "./components/ProfileDetails";
import SightingHistory from "./components/SightingHistory";

const BodyContainer = styled.div`
  width: 100%;
  margin-top: 20px;
`;

const ProfileContainer = styled.div`
  display: flex;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  flex-wrap: wrap;
`;

const ProfileDetailContainer = styled.div`
  min-width: 320px;
  max-width: 40%;

  @media (min-width: 800px) {
    max-width: 40%;
  }

  @media (max-width: 799px) {
    max-width: 100%;
  }
`;

const ProfileImageContainer = styled.div`
  min-width: 320px;
  max-width: 60%;
  max-height: 600px;

  @media (min-width: 800px) {
    max-width: 60%;
  }

  @media (max-width: 799px) {
    max-width: 100%;
  }
`;

const SightingContainer = styled.div`
  width: 100%;
  margin-top: 20px;
`;

const SightingHistoryContainer = styled.div`
  width: 100%;
  margin-top: 20px;
`;

const SightingHistoryTableContainer = styled.div`
  overflow: scroll;
`;

const LineageContainer = styled.div`
  width: 100%;
  margin-top: 20px;
`;

const LeopardProfile = () => {
  let { id } = useParams<{ id: string }>();
  let [leopard, setLeopard] = useState(null);
  let [sightings, setSightings] = useState([]);
  let [images, setImages] = useState([]);

  useEffect(() => {
    getLeopard(id).then((res) => {
      setLeopard(res);
      setImages(
        res.images.map(
          (i) =>
            ({
              src: AppConstant.IndividualImages.concat("/")
                .concat(id)
                .concat("/")
                .concat(i),
            } as GalleryImage)
        )
      );
    });

    getSightings(id).then((res) => {
      setSightings(res);
    });
  }, [id]);

  const title = leopard?.name ? `${id} - ${leopard.name}` : id;

  return (
    <MainLayout Title={title}>
      <BodyContainer>
        <ProfileContainer>
          <ProfileDetailContainer>
            <ProfileDetails Detail={leopard} />
          </ProfileDetailContainer>
          <ProfileImageContainer>
            <ImageGallery Images={images}></ImageGallery>
          </ProfileImageContainer>
        </ProfileContainer>
        <SightingContainer>
          <IndividualSightingDistribution sighting={sightings} />
        </SightingContainer>
        <SightingHistoryContainer>
          <Title Type={TitleType.SectionTitle}>Sighting History</Title>
          <SightingHistoryTableContainer>
            <SightingHistory sightings={sightings} />
          </SightingHistoryTableContainer>
        </SightingHistoryContainer>
        <LineageContainer>
          {leopard && <Lineage Lineage={leopard.lineage} CC={leopard.cc} />}
        </LineageContainer>
      </BodyContainer>
    </MainLayout>
  );
};

export default LeopardProfile;
