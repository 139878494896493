import React from "react";
import styled from "styled-components";
import { LatestSighting } from "../../../../application/models/Sighting";
import { Link } from "react-router-dom";

const Card = styled.li`
  width: "12%";
  // min-width:200px;
  // max-width:300px;
  margin: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
`;

const CardImage = styled.img`
  width: 250px;
`;

const CardDetail = styled.div`
  padding: 5px;
`;

const CardTitle = styled.div`
  width: "100%";
  font-weight: bold;
  padding: 5px;
`;

const Details = styled.div`
  padding: 5px;
  font-size: 12px;
`;

const CardLink = styled(Link)`
  text-decoration: none;
  cursor: pointer;
  color: #000;
`;

interface Props {
  Item: LatestSighting;
}

const SightingItem = ({ Item: item }: Props) => {
  let thumbnailUrl: string = "/images/leopards/thumbnails/".concat(
    item.thumbnail
  );
  return (
    <CardLink to={`leopard-profiles/${item.leopardId}`}>
      <Card>
        <CardImage src={thumbnailUrl} />
        <CardDetail>
          <CardTitle>
            {item.leopardId} - {item.name}
          </CardTitle>
          <Details>{item.date.concat(" ", item.time)}</Details>
        </CardDetail>
      </Card>
    </CardLink>
  );
};

export default SightingItem;
