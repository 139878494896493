import React from "react";
import styled from "styled-components";
import HomeLayout from "../../components/layouts/HomeLayout/HomeLayout";
import LatestSightings from "./components/LatestSightings";
import Overview from "./components/Overview";
import SightingDistribution from "./components/SightingDistributution";
import HomePhotoGallery from "./components/HomePhotoGallery";

// tslint:disable-next-line: typedef
const HomeContainer = styled.div`
    display: flex;
    flex-direction: column;
    width:100%;
`;

// tslint:disable-next-line: typedef
const OverviewContainer = styled.div`
    padding: 20px;
    width: 60%;

    @media (min-width: 750px) {
        width: 60%;
    };

    @media (max-width: 750px) {
        width: 100%;
    };
`;

// tslint:disable-next-line: typedef
const SightingContainer = styled.div`
    padding: 50px 5px 20px; 5px;
    width: 100%;
`;

const LatestSightingsContainer = styled.div`
    padding: 50px 5px 20px; 5px;
    width: 100%;
`;

const ImageGalleryContainer = styled.div`
    padding: 50px 5px 20px; 5px;
    width: 100%;
`;

// tslint:disable-next-line: typedef
const Home = () => {
    return(
        <HomeLayout>
            <HomeContainer>
                <OverviewContainer>
                    <Overview />
                </OverviewContainer>
                <SightingContainer>
                    <SightingDistribution />
                </SightingContainer>
                <LatestSightingsContainer>
                    <LatestSightings />
                </LatestSightingsContainer>
                <ImageGalleryContainer>
                    <HomePhotoGallery />
                </ImageGalleryContainer>
            </HomeContainer>
        </HomeLayout>
    );
};

export default Home;