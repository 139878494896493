import React from 'react';
import './App.css';
import AppContainer from './AppContainer';
 import {
   QueryClient,
   QueryClientProvider,
 } from 'react-query'

 const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient} >
         <AppContainer />
    </QueryClientProvider>
  );
}

export default App;
