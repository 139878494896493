import { Form, Field, useField, FieldHookConfig } from "formik";
import React from "react";
import styled from "styled-components";
import { Col } from './GridView';


export const FormField = styled(Field)`
    padding: 12px 20px;
    margin: 8px 10px;
    box-sizing: border-box;
    border: 2px solid gray;
    border-radius: 4px;
    font-size: 14px;
    font-family: Arial, Helvetica, sans-serif;
 `;

export const FormLabel = styled.label`
    font-size: 14px;
 `;

export const StyledForm = styled(Form)`
    display: flex;
    width: 100%;
`;

interface IFormControl {
    Id: string;
    Name: string;
    Label?: string;
    Placeholder?: string;
    Width?: string;
    onChange?: () => void;
    Value?: string;
}

export const FormControl =
    ({
        Id:id,
        Name: name,
        Label: label,
        Placeholder: placeholder,
        Width: width,
        onChange,
        Value: value
     } : IFormControl
    ) => {
    return(
        <Col Width={ width }>
            { label && <FormLabel htmlFor={id}>{label}</FormLabel>}
            <FormField id={id} name={name} placeholder={placeholder} onChange={onChange} value={value}/>
        </Col>
    );
};


const StyledTextArea = styled.textarea`
    padding: 12px 20px;
    margin: 8px 10px;
    box-sizing: border-box;
    border: 2px solid gray;
    border-radius: 4px;
    font-size: 16px;
    font-family: Arial, Helvetica, sans-serif;
`;

interface ITextAreaProps {
    label?: string;
    placeholder?: string;
    width?: string;
    rows?: number;
}

type TextAreaProps = ITextAreaProps;

export const TextAreaControl = ({
    //label,
    placeholder,
    rows,
    ...props}) => {

    const fieldProps = props as FieldHookConfig<string>;
    const [field, meta] = useField<any>(fieldProps);

    return (
        <Col Width={ props.width }>
            {/* { label && <FormLabel htmlFor={props.id || props.name}>{label}</FormLabel>} */}
            <StyledTextArea
                value={meta.value}
                onChange={field.onChange}
                placeholder={placeholder}
                rows={rows ? rows : 10}
                {...field}
                {...props}
            />
            {meta.touched && meta.error ? (
              <div className="error">{meta.error}</div>
          ) : null}
        </Col>
    );
};

