import React from 'react';
import styled from 'styled-components';
import MainNavBar from '../../../shared/MainNavBar';
import Title, { TitleType } from '../../../shared/Title';

const HeaderContainer = styled.div`
    display:flex;
    flex-direction: column;
    width: 100%;
    background: url('/images/banner.jpg') no-repeat;
    background-size: 100% auto;
`;

const TitleContainer = styled.div`
    display:flex;
    flex: 1;
    justify-content:center;
    align-items: center;
`;

interface IHeaderProps {
    Title?: string;
}

const  Header = ({Title: title} : IHeaderProps) => {
    return(
        <HeaderContainer>
            <MainNavBar Justify="center" DisplayLogo={true} />
            <TitleContainer>
                <Title Type={TitleType.MainTitle} BackgroundColor="transparent" Color="#fff">{title || "Test"}</Title>
            </TitleContainer>
        </HeaderContainer>
    );
};

export default Header;