import styled from "styled-components";

const MapContainer = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  object {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const KumanaMap = () => {
  return (
    <MapContainer>
      <iframe
        src="https://www.google.com/maps/d/u/0/embed?mid=1as2BZz4hPIoWXCTIHwqAd5i7F-peBoQ&ehbc=2E312F&noprof=1"
        width="800"
        height="600"
      ></iframe>
    </MapContainer>
  );
};

export default KumanaMap;
