import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Dashboard from "./dashboard/Dashboard";

const AdminContainer = ({match}) => {
    return(
        <>
            <Route path={match.url + "/"}> <Dashboard /> </Route>
        </>
    );
};

export default AdminContainer;



