import { keyframes } from "styled-components";
import styled  from "styled-components";

const Container = styled.div`
    display: flex;
    width: 100%;
    height: 500px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);
  
  border-top: 2px solid grey;
  border-right: 2px solid grey;
  border-bottom: 2px solid grey;
  border-left: 4px solid black;
  background: transparent;
  width: 100px;
  height: 100px;
  border-radius: 50%;
`;

const Loading = () => {
    return(
        <Container>
            <Spinner />
        </Container>
    );
};

export default Loading;