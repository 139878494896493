import React from 'react';
import styled from 'styled-components';
import MainNavBar from '../../../shared/MainNavBar';

const HeaderContainer = styled.div`
    width: 100%;
    background: url('/images/home-banner.jpg') no-repeat;
    background-size: contain;
    resize: both;
    padding: 20px;
    position: relative;
`;

const HeaderImage = styled.img`
    visibility: hidden;
    width: 100%;
    height: auto;
`;

const OverviewImageContainer = styled.div`
    display: flex;
    justify-content: center;
`;

const OverviewImageRaw = styled.div`
    width: 100%;
    position: absolute;
    bottom: -15%;
    display: flex;
`;

const BlankColumn = styled.div`
    width: 60%;
`;


const OverviewImage = styled.img`
    width: 60%;
    height: auto;

    @media (min-width: 750px) {
       width: 60%;
       display: block;
    };
  
    @media (max-width: 750px) {
        display: none;
    };
`;

const  Header = () => {
    return(
        <HeaderContainer>
            <MainNavBar DisplayLogo={true}/>
            <OverviewImageRaw>
                <BlankColumn>&nbsp;</BlankColumn>
                <OverviewImageContainer>
                    <OverviewImage src="/images/site-overview.jpg"/>
                </OverviewImageContainer>
            </OverviewImageRaw>
            <HeaderImage src="/images/home-banner.jpg"/>
        </HeaderContainer>
    );
};

export default Header;