import { useEffect, useState } from "react";
import styled from "styled-components";
import { AppConstant } from "../../../../app/Constants";
import { Sighting } from "../../../../application/models/Sighting";
import Map, {MapMarker, Position} from "../../../components/shared/Map";
import Title, { TitleType } from "../../../components/shared/Title";

interface ISightingDistributionProps {
    sighting: Sighting[];
}

const IndividualSightingDistribution = ({sighting}: ISightingDistributionProps) => {
    let [markers, setMarkers] = useState([]);
    let [center, setCenter] = useState(null);

    useEffect(() => {
        let markers = sighting.map(s => ({
            id: s.id,
            thumbnail: "/images/pug-icon.png",
            leopardId: s.leopardId,
            title: s.displaydate.concat(" ", s.time),
            position: {lat: Number(s.lat), lng: Number(s.lang)} as Position,
            sighting: s
        }));

        setMarkers(markers);
        setCenter(markers.length > 0 ? markers[0].position : null);
    },[sighting]);

    return (
        <>
        <Title Type={TitleType.SectionTitle}>Sighting Distribution</Title>
            <Map  center={center} markers={markers} zoom={15} height="700px" />
        </>
    );
};

export default IndividualSightingDistribution;
