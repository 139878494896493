import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';


const LogoContainer = styled.div`
    display: flex;
    color: #fff;
`;

const LogoLink = styled(Link)`
    display: flex;
    width: 200px;
    height: 100px;
    background: url('/logo.png') no-repeat;
    background-size: 100% auto;
    margin-top: 10px;
    margin-left: 10px;
`;

const Logo = () => {
    return(
        <LogoContainer>
            <LogoLink to="/">
                &nbsp;
            </LogoLink>
        </LogoContainer>
    );
};

export default Logo;