import React from "react";
import styled from "styled-components";
import Header from "./components/Header";
import Footer from "../../shared/Footer";
import MainContainer from "../../shared/MainContainer";
import Copyright from "../../shared/Copyright";

const LayoutContainer = styled.div`
    display:flex;
    flex-direction: column;
    width: 100%;
`;

const HeaderContainer = styled.div`
    display:flex;
    width: 100%;
`;

const BodyContainer = styled.div`
    display: flex;
    width: 100%;
`;

const FooterContainer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
`;


const HomeLayout = ({children}) => {
    return(
        <MainContainer>
            <LayoutContainer>
                <HeaderContainer>
                    <Header />
                </HeaderContainer>
                <BodyContainer>
                    {children}
                </BodyContainer>
                <FooterContainer>
                    <Footer />
                    <Copyright />
                </FooterContainer>
            </LayoutContainer>
        </MainContainer>
    );
};

export default HomeLayout;