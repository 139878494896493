import { useState } from "react";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { getContributions } from "../../../application/SightingService";
import MainLayout from "../../components/layouts/MainLayout/MainLayout";
import Paragraph from "../../components/shared/Paragraph";
import SubTitle from "../../components/shared/SubTitle";
import { Table, TableCell, TableRow } from "../../components/shared/Table";

const BodyContainer = styled.div`
    margin: 20px;
`;

const CopyrightsContainer = styled.div`

`;

const CreditContainer = styled.div`
    margin-top: 50px;
    width: 100%;
`;

const CreditList = styled.ul`
    width: 800px;
    display: flex;
    flex-wrap: wrap;
`;

const CreditItem = styled.li`
    min-width: 350px;
    list-style-type: none;
    margin: 0;
    padding: 0;
    flex-wrap: wrap;
    justify-content: space-around;
`;



const Copyright = () => {

    let contribution = [
        "Alagiah Lathaharan",
        "Anupa Sanjana Wickramarachchi",
        "Caryll Tozer",
        "Charith Jayathilake",
        "Chathurani Ekanayake",
        "Chris Perera",
        "Danushka Senadheera",
        "Dhanushka Chaminda Kumara Liyanage",
        "Dileep Dilantha Samaranayaka",
        "Dinal Gunaratne",
        "Dinuka Ranasinghe",
        "Dinith Pathirage",
        "Dushan Senarathna",
        "Eshan Goonetilleke",
        "Harikaran Hari",
        "Heshan Peiris",
        "Hiranjana Dilshan Balasooriya",
        "Imash Minoka",
        "Nilushan Wijesinghe",
        "Irshard Jemzeed",
        "Ismail Salahudeen",
        "Kushan Sameera",
        "Lahiru Sasanka Liyanage",
        "Lochana Kodithuwakku",
        "Luxman Premarathna",
        "Malika Soyza Munaweera",
        "Malkshan Fernando",
        "Manindra Dharmadasa",
        "Nadarajah Mauran",
        "Nalin Kaveendra",
        "Pramod Chamalka Sendanayake",
        "Prashanth Jayasekara",
        "Priyantha Serasinghe",
        "Randika Udagedara",
        "Rasika Silva",
        "Rishi Gunasinghe",
        "Riznad Hussain",
        "Roshana Prasad",
        "Ruwanjala Jayawardena",
        "Sajith Atalugama",
        "Saman Abeygunawardane",
        "Saman Halloluwa",
        "Samitha Harischandra",
        "Sankha Wanniatchi",
        "Saranga Dinusha Lanka Geeganage",
        "Sasika Sanjeewa",
        "Shanaka Kalubowila",
        "Shanith Sayandhan",
        "Shantha Wimaladharma",
        "Susantha Kumara",
        "Thaamara Kariyawasam",
        "Theekshana Lakshan",
        "Toshan Wijeratne",
        "Udara Karunarathna",
        "Wasantha Disanayaka",
        "Yohan Dole",
    ];

    let [credits, setCredits] = useState(new Set());

    let {isLoading, error, data} = useQuery("credits", () => getContributions());

    useEffect(() => {
        let credit = contribution.concat(data);
        setCredits(new Set(credit));
    }, data)

    return(
        <MainLayout Title="Credits & Copyright">
            <BodyContainer>
                <CopyrightsContainer>
                    <SubTitle>Copyright Note</SubTitle>
                    <Paragraph>
                        Leopards of Kumana
                    </Paragraph>
                    <Paragraph>
                        All Rights Reserved Worldwide.
                    </Paragraph>
                    <Paragraph>
                        Permission must be obtained before making copies or reproducing any written or photographic content on the Leopards of Kumana web site. All content of this website is protected under the intellectual property act, No. 36 of 2003 Sri Lanka.
                    </Paragraph>
                    <Paragraph>
                    Copyright protection of the Leopards of Kumana web site encompasses all its contributors. Authors and photographers hold ownership of their individual materials/content published in this web site. 
                    </Paragraph>
                    <Paragraph>
                        Any photograph, document, page, map or portions of them thereof posted on this World Wide Web site may not be reprinted, published, broadcasted or distributed by any means mechanically or electronically without the prior written permission of Copyright owners (Authors of Leopards of Kumana).
                    </Paragraph>
                    <Paragraph>
                        To request any further information or to answer any additional questions please contact us via email, kumanaleopards@gmail.com or <Link to="/contact-us">click here</Link>
                    </Paragraph>
                </CopyrightsContainer>
                <CreditContainer>
                    <SubTitle>Credits</SubTitle>
                    <Paragraph>
                        Support of wildlife enthusiasts below has been significant in respect of gathering data for the study. They have contributed to this work by means of providing observation data and photographs. Their commitment & support is celebrated in high regard throughout this work.
                    </Paragraph>
    
                        <CreditList>
                        {
                            credits && Array.from(credits).sort((a, b) => a > b ? 1 : -1).map((c, i) => <CreditItem>{c}</CreditItem>)
                        }
                        </CreditList>
  
                </CreditContainer>
            </BodyContainer>
        </MainLayout>

    );
};

export default Copyright;