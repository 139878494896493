import React from "react";
import { Link, useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import { AppConstant } from "../../../../app/Constants";

interface ICardProps {
  BackgroundColor: string;
}

const Card = styled.li<ICardProps>`
  min-width: 250px;
  margin: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  background-color: ${(props) => props.BackgroundColor};
`;

const CardLink = styled(Link)`
  text-decoration: none;
  color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CardImage = styled.img`
  width: 100%;
  width: 300px;
  height: 400px;
`;

const CardDetail = styled.div`
  padding: 5px;
`;

const CardTitle = styled.div`
  width: "100%";
  font-weight: bold;
  padding: 5px;
`;

interface ILeopardListItemProps {
  LeopardId: string;
  name?: string;
  ImageUrl: string;
  Gender: string;
}

const LeopardListItem = ({
  LeopardId: id,
  name,
  ImageUrl: imageUrl,
  Gender: gender,
}: ILeopardListItemProps) => {
  let { url } = useRouteMatch();
  let thumbnailUrl = "/images/leopards/thumbnails/".concat(imageUrl);

  return (
    <Card
      BackgroundColor={
        gender.toLowerCase() === "male"
          ? AppConstant.MaleColor
          : AppConstant.FemaleColor
      }
    >
      <CardLink to={`${url}/${id}`}>
        <CardImage src={thumbnailUrl} />
        <CardDetail>
          <CardTitle>
            {id} {name ? `- ${name}` : null}
          </CardTitle>
        </CardDetail>
      </CardLink>
    </Card>
  );
};

export default LeopardListItem;
