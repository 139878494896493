import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  GoogleMap,
  InfoWindow,
  LoadScript,
  Marker,
} from "@react-google-maps/api";
import { AppConstant } from "../../../app/Constants";
import { Sighting } from "../../../application/models/Sighting";
import MapInfoWindow from "./MapInfoWindow";
import { getLocations } from "../../../application/LocationService";
import { MapLocation } from "../../../application/models/Location";

// tslint:disable-next-line: typedef
const MapContainer = styled.div`
    margin-top: 20px;
    width: "100%"
    height: "100%"
`;

const MapLegend = styled.div`
    display: flex;
    margin: 10px; 0px;
    width:100%;
    flex-wrap:wrap;
`;

const LegendItem = styled.div`
  padding: 10px;
  min-width: 200px;
  display: flex;
  height: 50px;
`;

export interface MapMarker {
  id: string;
  position: Position;
  dateTime?: string;
  title: string;
  thumbnail: string;
  leopardId: string;
  sighting: Sighting;
}

export interface Position {
  lat: number;
  lng: number;
}

interface MapProps {
  markers?: MapMarker[];
  center?: Position;
  height?: string;
  width?: string;
  zoom?: number;
  hideMarkerLabel?: boolean;
}

// tslint:disable-next-line: typedef
const Map = ({
  markers,
  center,
  height,
  width,
  zoom,
  hideMarkerLabel,
}: MapProps) => {
  let [selected, setSelected] = useState(null);
  let [locations, setLocations] = useState([]);
  let [mapCenter, setMapCenter] = useState({
    lat: 6.5976076,
    lng: 81.6462899,
  });

  useEffect(() => {
    getLocations().then((res) => {
      setLocations(res);
    });
  }, []);

  useEffect(() => {
    if (center) {
      setMapCenter(center);
    }
  }, [center]);

  const containerStyle = {
    width: width ? width : "100%",
    height: height ? height : "500px",
  };

  zoom = zoom ? zoom : 14;

  const getIcon = (leopardId: string) => {
    let getColor = (id: string) => {
      let gender: string = id.slice(0, 3).toLowerCase();
      switch (gender) {
        case "klm":
          return AppConstant.MaleColor;
        case "klf":
          return AppConstant.FemaleColor;
        default:
          return "#c41434";
      }
    };

    return {
      path: `m50.000999,43.429001c6.006001,0 11.056,4.105999 12.485001,9.660999c1.800003,6.959999 1.545998,14.594997 9.434998,18.551998c6.995003,2.402 9.639,5.419998 9.639,13.504005c0,6.454994 -5.473999,13.312996 -12.538994,14.168999c-7.889004,1.195999 -14.040005,-0.366005 -19.020004,-3.233002c-4.980999,2.866997 -11.132,4.43 -19.017,3.233002c-7.067999,-0.856003 -12.542999,-7.739006 -12.542999,-14.168999c0,-7.909004 2.823999,-11.260002 9.809,-13.554001c8.591,-4.422005 7.438,-12.178001 9.355999,-18.843002c1.549,-5.382 6.510002,-9.32 12.395,-9.32l0,0zm39.368004,-4.806999c-2.128006,-1.868 -10.823006,5.413998 -13.554001,9.016998c-1.667,1.919998 -2.697006,4.562 -2.697006,7.477001c0,5.863998 4.156006,10.615002 9.281006,10.615002c3.894997,0 7.223999,-2.734001 8.603996,-6.617004c2.681,-6.979 2.820999,-16.588997 -1.633995,-20.491997l0,0zm-78.741003,0c-4.455,3.903 -4.312,13.512997 -1.628,20.491997c1.378,3.883003 4.709,6.617004 8.601,6.617004c5.125,0 9.278999,-4.751003 9.278999,-10.615002c0,-2.915001 -1.028999,-5.557003 -2.695,-7.477001c-2.733,-3.603001 -11.424,-10.884998 -13.556999,-9.016998l0,0zm50.810999,-38.277002c21.636997,3.699 20.116001,40.427999 2.793003,37.467c-4.996002,-0.854 -8.703003,-5.445 -9.265003,-10.923c-0.577,-5.572001 -1.864998,-27.968 6.472,-26.544l0,0zm-22.880001,0c8.34,-1.424 7.049004,20.971999 6.476002,26.544c-0.564999,5.478001 -4.269001,10.068001 -9.265999,10.923c-17.327002,2.960999 -18.844002,-33.768 2.789997,-37.467z`,
      fillColor: getColor(leopardId),
      fillOpacity: 1,
      strokeColor: "#ffffff",
      strokeWeight: 0.3,
      scale: 0.17,
    };
  };

  const getMapLocationIcon = () => {
    return {
      path: `M188.805,96.394c-1.119,3.125-4.08,5.211-7.4,5.211h-78.743c-4.341,0-7.86-3.518-7.86-7.86V88.66H49.392v85.418
	c6.825,3.028,11.604,9.857,11.604,17.792c0,10.732-8.731,19.464-19.464,19.464s-19.464-8.731-19.464-19.464
	c0-7.935,4.779-14.765,11.604-17.792V80.8V21.95V7.86c0-4.341,3.519-7.86,7.86-7.86s7.86,3.518,7.86,7.86v6.231h70.883
	c4.341,0,7.86,3.518,7.86,7.86v5.085h53.27c3.32,0,6.281,2.086,7.4,5.211c1.119,3.126,0.152,6.617-2.414,8.723l-28.447,23.35
	l28.447,23.35C188.957,89.776,189.923,93.267,188.805,96.394z`,
      fillColor: "#16c8bc",
      fillOpacity: 1,
      scale: 0.07,
    };
  };

  // tslint:disable-next-line: typedef
  const reposition = (m) => {
    console.log(m);
    setSelected(m);
  };

  const getPosition = (location: MapLocation) => {
    let position: Position = {
      lat: Number(location.lat),
      lng: Number(location.lang),
    };

    return position;
  };

  const getLabel = (hideLabel: boolean, title: string) => {
    return hideLabel
      ? null
      : {
          text: title,
          color: "#ffffff",
          fontSize: "14px",
          fontWeight: "bold",
        };
  };

  return (
    <MapContainer>
      <LoadScript googleMapsApiKey={AppConstant.MapKey}>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={{ lat: mapCenter.lat, lng: mapCenter.lng }}
          zoom={zoom}
          mapTypeId="satellite"
        >
          {locations &&
            locations.map((l) => (
              <Marker
                key={"location_".concat(l.id)}
                position={getPosition(l)}
                title={l.name}
                icon={getMapLocationIcon()}
              />
            ))}
          ;
          {
            /* Child components, such as markers, info windows, etc. */
            markers &&
              markers.map((m) => (
                <Marker
                  key={m.id}
                  position={m.position}
                  title={m.title}
                  icon={getIcon(m.leopardId)}
                  label={getLabel(hideMarkerLabel, m.title)}
                  onClick={() => reposition(m)}
                />
              ))
          }
          ;
          {selected && (
            <InfoWindow
              position={selected.position}
              onCloseClick={() => setSelected(null)}
            >
              <MapInfoWindow sighting={selected.sighting} />
            </InfoWindow>
          )}
          ;
        </GoogleMap>
      </LoadScript>
      <MapLegend>
        <LegendItem>
          <svg viewBox="0 0 150 150" xmlns="http://www.w3.org/2000/svg">
            <path
              d="m50.000999,43.429001c6.006001,0 11.056,4.105999 12.485001,9.660999c1.800003,6.959999 1.545998,14.594997 9.434998,18.551998c6.995003,2.402 9.639,5.419998 9.639,13.504005c0,6.454994 -5.473999,13.312996 -12.538994,14.168999c-7.889004,1.195999 -14.040005,-0.366005 -19.020004,-3.233002c-4.980999,2.866997 -11.132,4.43 -19.017,3.233002c-7.067999,-0.856003 -12.542999,-7.739006 -12.542999,-14.168999c0,-7.909004 2.823999,-11.260002 9.809,-13.554001c8.591,-4.422005 7.438,-12.178001 9.355999,-18.843002c1.549,-5.382 6.510002,-9.32 12.395,-9.32l0,0zm39.368004,-4.806999c-2.128006,-1.868 -10.823006,5.413998 -13.554001,9.016998c-1.667,1.919998 -2.697006,4.562 -2.697006,7.477001c0,5.863998 4.156006,10.615002 9.281006,10.615002c3.894997,0 7.223999,-2.734001 8.603996,-6.617004c2.681,-6.979 2.820999,-16.588997 -1.633995,-20.491997l0,0zm-78.741003,0c-4.455,3.903 -4.312,13.512997 -1.628,20.491997c1.378,3.883003 4.709,6.617004 8.601,6.617004c5.125,0 9.278999,-4.751003 9.278999,-10.615002c0,-2.915001 -1.028999,-5.557003 -2.695,-7.477001c-2.733,-3.603001 -11.424,-10.884998 -13.556999,-9.016998l0,0zm50.810999,-38.277002c21.636997,3.699 20.116001,40.427999 2.793003,37.467c-4.996002,-0.854 -8.703003,-5.445 -9.265003,-10.923c-0.577,-5.572001 -1.864998,-27.968 6.472,-26.544l0,0zm-22.880001,0c8.34,-1.424 7.049004,20.971999 6.476002,26.544c-0.564999,5.478001 -4.269001,10.068001 -9.265999,10.923c-17.327002,2.960999 -18.844002,-33.768 2.789997,-37.467z"
              fill={AppConstant.MaleColor}
            />
          </svg>{" "}
          Male
        </LegendItem>
        <LegendItem>
          <svg viewBox="0 0 150 150" xmlns="http://www.w3.org/2000/svg">
            <path
              d="m50.000999,43.429001c6.006001,0 11.056,4.105999 12.485001,9.660999c1.800003,6.959999 1.545998,14.594997 9.434998,18.551998c6.995003,2.402 9.639,5.419998 9.639,13.504005c0,6.454994 -5.473999,13.312996 -12.538994,14.168999c-7.889004,1.195999 -14.040005,-0.366005 -19.020004,-3.233002c-4.980999,2.866997 -11.132,4.43 -19.017,3.233002c-7.067999,-0.856003 -12.542999,-7.739006 -12.542999,-14.168999c0,-7.909004 2.823999,-11.260002 9.809,-13.554001c8.591,-4.422005 7.438,-12.178001 9.355999,-18.843002c1.549,-5.382 6.510002,-9.32 12.395,-9.32l0,0zm39.368004,-4.806999c-2.128006,-1.868 -10.823006,5.413998 -13.554001,9.016998c-1.667,1.919998 -2.697006,4.562 -2.697006,7.477001c0,5.863998 4.156006,10.615002 9.281006,10.615002c3.894997,0 7.223999,-2.734001 8.603996,-6.617004c2.681,-6.979 2.820999,-16.588997 -1.633995,-20.491997l0,0zm-78.741003,0c-4.455,3.903 -4.312,13.512997 -1.628,20.491997c1.378,3.883003 4.709,6.617004 8.601,6.617004c5.125,0 9.278999,-4.751003 9.278999,-10.615002c0,-2.915001 -1.028999,-5.557003 -2.695,-7.477001c-2.733,-3.603001 -11.424,-10.884998 -13.556999,-9.016998l0,0zm50.810999,-38.277002c21.636997,3.699 20.116001,40.427999 2.793003,37.467c-4.996002,-0.854 -8.703003,-5.445 -9.265003,-10.923c-0.577,-5.572001 -1.864998,-27.968 6.472,-26.544l0,0zm-22.880001,0c8.34,-1.424 7.049004,20.971999 6.476002,26.544c-0.564999,5.478001 -4.269001,10.068001 -9.265999,10.923c-17.327002,2.960999 -18.844002,-33.768 2.789997,-37.467z"
              fill={AppConstant.FemaleColor}
            />
          </svg>
          Female
        </LegendItem>
        <LegendItem>
          <svg viewBox="0 0 150 150" xmlns="http://www.w3.org/2000/svg">
            <path
              d="m50.000999,43.429001c6.006001,0 11.056,4.105999 12.485001,9.660999c1.800003,6.959999 1.545998,14.594997 9.434998,18.551998c6.995003,2.402 9.639,5.419998 9.639,13.504005c0,6.454994 -5.473999,13.312996 -12.538994,14.168999c-7.889004,1.195999 -14.040005,-0.366005 -19.020004,-3.233002c-4.980999,2.866997 -11.132,4.43 -19.017,3.233002c-7.067999,-0.856003 -12.542999,-7.739006 -12.542999,-14.168999c0,-7.909004 2.823999,-11.260002 9.809,-13.554001c8.591,-4.422005 7.438,-12.178001 9.355999,-18.843002c1.549,-5.382 6.510002,-9.32 12.395,-9.32l0,0zm39.368004,-4.806999c-2.128006,-1.868 -10.823006,5.413998 -13.554001,9.016998c-1.667,1.919998 -2.697006,4.562 -2.697006,7.477001c0,5.863998 4.156006,10.615002 9.281006,10.615002c3.894997,0 7.223999,-2.734001 8.603996,-6.617004c2.681,-6.979 2.820999,-16.588997 -1.633995,-20.491997l0,0zm-78.741003,0c-4.455,3.903 -4.312,13.512997 -1.628,20.491997c1.378,3.883003 4.709,6.617004 8.601,6.617004c5.125,0 9.278999,-4.751003 9.278999,-10.615002c0,-2.915001 -1.028999,-5.557003 -2.695,-7.477001c-2.733,-3.603001 -11.424,-10.884998 -13.556999,-9.016998l0,0zm50.810999,-38.277002c21.636997,3.699 20.116001,40.427999 2.793003,37.467c-4.996002,-0.854 -8.703003,-5.445 -9.265003,-10.923c-0.577,-5.572001 -1.864998,-27.968 6.472,-26.544l0,0zm-22.880001,0c8.34,-1.424 7.049004,20.971999 6.476002,26.544c-0.564999,5.478001 -4.269001,10.068001 -9.265999,10.923c-17.327002,2.960999 -18.844002,-33.768 2.789997,-37.467z"
              fill="#c41434"
            />
          </svg>
          Unidentified
        </LegendItem>
        <LegendItem>
          <svg viewBox="0 0 300 300" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M188.805,96.394c-1.119,3.125-4.08,5.211-7.4,5.211h-78.743c-4.341,0-7.86-3.518-7.86-7.86V88.66H49.392v85.418
	c6.825,3.028,11.604,9.857,11.604,17.792c0,10.732-8.731,19.464-19.464,19.464s-19.464-8.731-19.464-19.464
	c0-7.935,4.779-14.765,11.604-17.792V80.8V21.95V7.86c0-4.341,3.519-7.86,7.86-7.86s7.86,3.518,7.86,7.86v6.231h70.883
	c4.341,0,7.86,3.518,7.86,7.86v5.085h53.27c3.32,0,6.281,2.086,7.4,5.211c1.119,3.126,0.152,6.617-2.414,8.723l-28.447,23.35
	l28.447,23.35C188.957,89.776,189.923,93.267,188.805,96.394z"
              fill="#16c8bc"
            />
          </svg>
          Location
        </LegendItem>
      </MapLegend>
    </MapContainer>
  );
};

export default Map;
