import {push as Menu} from 'react-burger-menu';
import MenuItem from './MenuItem';


var styles = {
  bmBurgerButton: {
    position: 'fixed',
    width: '24px',
    height: '20px',
    right: '36px',
    top: '20px'
  },
  bmBurgerBars: {
    background: '#fff'
  },
  bmBurgerBarsHover: {
    background: '#a90000'
  },
  bmCrossButton: {
    height: '14px',
    width: '24px'
  },
  bmCross: {
    background: '#bdc3c7'
  },
  bmMenuWrap: {
    height: '100%'
  },
  bmMenu: {
    background: '#373a47',
    padding: '2.5em 1.5em 0',
    fontSize: '1.15em'
  },
  bmMorphShape: {
    fill: '#373a47'
  },
  bmItemList: {
    color: '#b8b7ad',
    padding: '0.8em'
  },
  bmItem: {
    display: 'inline-block'
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)'
  }
};

const HamburgerMenuBar = () => {
    return(
        <Menu styles={styles} noOverlay right={true}>
                <MenuItem LinkTo="/kumana-national-park" >Kumana National Park</MenuItem>
                <MenuItem LinkTo="/leopard-profiles">Leopard Profiles</MenuItem>
                {/* <MenuItem LinkTo="/statistics">Statistics</MenuItem> */}
                <MenuItem LinkTo="/corelation-data">Co-relation Data</MenuItem>
                <MenuItem LinkTo="/gallery">Gallery</MenuItem>
                <MenuItem LinkTo="/about">About Us</MenuItem>
                <MenuItem LinkTo="/contribute">Contribute</MenuItem>
        </Menu>
    );
};


export default HamburgerMenuBar;