import React from 'react'
import styled from "styled-components";

const Body = styled.div`
    display:flex;
    justify-content: space-around;
    
`;

const Content = styled.div`
    display:flex;
    width: 100%;
    flex-direction: row;
`;

const MainContainer = ({children}) => {
    return(
        <Body>
            <Content>
                {children}
            </Content>
        </Body>
    );
};

export default MainContainer;