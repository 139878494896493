import React, { useState } from "react";
import styled from "styled-components";
import { Filter } from "../../../../application/models/Leopard";
import Select from "react-select";
import { useQuery } from "react-query";
import { getSightingLocations } from "../../../../application/SightingService";

const SearchContainer= styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 20px 0px;
`;

const SearchInputContainer= styled.div`
    width: 100%;
    display: flex;
    justify-content: space-around;
    padding: 10px 0px;
    flex-wrap: wrap;
`;

const SearchButtonContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 10px 0px;
`;

interface SearchSelectProps {
    isClearable: boolean;
}

const SearchSelect = styled(Select).attrs(props => ({
 isClearable: true
})) `
    width: 45%;
    padding: 12px 20px;
    margin: 8px 0;
    font-size: 16px;
    min-width: 300px;
`;


interface SearchBoxProps {
    search: (filter: Filter) => void;
}

const SearchBox = ({search}: SearchBoxProps) => {

    let [searchFilter, setSearchFilter] = useState({
        Gender : "",
        Location: ""
    });

    let {isLoading, error, data} = useQuery("locations", () => getSightingLocations());

    const genderOptions = [
        { value: 'male', label: 'Male' },
        { value: 'female', label: 'Female' }
    ];

    const handleGenderChange = (key) => {
        setSearchFilter({
            ...searchFilter,
            Gender: key ? key.value : ""
        });

        search({
            ...searchFilter,
            Gender: key ? key.value : ""
        });
    };

    const handleLocationChange = (key) => {
         setSearchFilter({
            ...searchFilter,
            Location: key ? key.value : ""
        });

        search({
            ...searchFilter,
            Location: key ? key.value : ""
        });
    };

    const getLocationOptions = (locations) => {
        return locations && locations.map(l => ({value: l, label: l}));
    };

    return(
        <SearchContainer>
            <SearchInputContainer>
                <SearchSelect options={genderOptions} placeholder="Select Gender" onChange={handleGenderChange} />
                <SearchSelect isLoading={isLoading} options={getLocationOptions(data)}
                    placeholder="Select Location" onChange={handleLocationChange}/>
            </SearchInputContainer>
        </SearchContainer>
    );
};

export default SearchBox;