import styled from "styled-components";

const StyledLink = styled.a`
    font-family: Arial, Helvetica, sans-serif;
    font-style: italic;
`;

interface IMailLinkProps {
    MailTo: string
}

const EmailLink = ({MailTo: mailTo}: IMailLinkProps) => {
   return(
        <StyledLink href="mailto:{mailTo}">{mailTo}</StyledLink>
    );
};

export default EmailLink;
