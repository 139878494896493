import styled from "styled-components";
import { LeopardProfile } from "../../../../application/models/Leopard";

const ProfileDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const DetailContainer = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 10px;
`;

const DetailItem = styled.li`
  list-style-type: none;
  margin: 0;
  padding: 2px 0px;
  display: flex;
  flex: 1;
`;

const DetailLabel = styled.span`
  width: 40%;
  background-color: #ded8d7;
  font-weight: bold;
  padding: 10px;
  height: 60px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

const Detail = styled.span`
  width: 60%;
  font-weight: bold;
  padding: 10px;
  display: inline-flex;
  align-items: center;
`;

const ArrowRight = styled.span`
  width: 0;
  height: 0;
  border-top: 30px solid transparent;
  border-bottom: 30px solid transparent;
  border-left: 30px solid #ded8d7;
`;

const SpecialObservation = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
`;

const SpecialObservationTitle = styled.div`
  background-color: #ded8d7;
  font-weight: bold;
  padding: 10px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

const SpecialObservationDetail = styled.div`
  padding: 10px;
`;

interface IDetailProps {
  Detail?: LeopardProfile;
}

const ProfileDetails = ({ Detail: detail }: IDetailProps) => {
  return (
    detail && (
      <ProfileDetailContainer>
        <DetailContainer>
          <DetailItem>
            <DetailLabel>Identification Code</DetailLabel>
            <ArrowRight />
            <Detail>{detail.id}</Detail>
          </DetailItem>
          {detail?.name ? (
            <DetailItem>
              <DetailLabel>Identification name</DetailLabel>
              <ArrowRight />
              <Detail>{detail.name}</Detail>
            </DetailItem>
          ) : null}
          <DetailItem>
            <DetailLabel>Gender</DetailLabel>
            <ArrowRight />
            <Detail>{detail.gender}</Detail>
          </DetailItem>
          <DetailItem>
            <DetailLabel>First Seen</DetailLabel>
            <ArrowRight />
            <Detail>{detail.firstSeen}</Detail>
          </DetailItem>
          <DetailItem>
            <DetailLabel>Last Seen</DetailLabel>
            <ArrowRight />
            <Detail>{detail.lastSeen}</Detail>
          </DetailItem>
          <DetailItem>
            <DetailLabel>Zone</DetailLabel>
            <ArrowRight />
            <Detail>{detail.zone}</Detail>
          </DetailItem>
        </DetailContainer>
        <SpecialObservation>
          <SpecialObservationTitle>Special Observation</SpecialObservationTitle>
          <SpecialObservationDetail>
            {detail.specialObservation}
          </SpecialObservationDetail>
        </SpecialObservation>
      </ProfileDetailContainer>
    )
  );
};

export default ProfileDetails;
