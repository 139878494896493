import React from "react";
import styled from "styled-components";
import { LeopardItem } from "../../../../application/models/Leopard";
import LeopardListItem from "./LeopardListItem";

const LeopardListContainer = styled.ul`
  display: flex;
  flex-direction: row;
  list-style-type: none;
  margin: 0;
  padding: 0;
  flex-wrap: wrap;
  justify-content: space-around;
`;

interface ILeopardListProps {
  Items?: LeopardItem[];
}

const LeopardList = ({ Items: items }: ILeopardListProps) => {
  console.log(items);
  return (
    <LeopardListContainer>
      {items &&
        items.map((i) => (
          <LeopardListItem
            name={i.name}
            key={i.id}
            Gender={i.gender}
            ImageUrl={i.thumbnail}
            LeopardId={i.id}
          />
        ))}
    </LeopardListContainer>
  );
};

export default LeopardList;
