import styled from "styled-components";
import { Sighting } from "../../../../application/models/Sighting";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderColumn,
  TableRow,
} from "../../../components/shared/Table";
import Title, { TitleType } from "../../../components/shared/Title";

const SightingTableContainer = styled.div`
  margin: 20px 0px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  min-width: 800px;
`;

interface ISightingHistoryProps {
  sightings: Sighting[];
}

const SightingHistory = ({ sightings }: ISightingHistoryProps) => {
  return (
    <>
      <SightingTableContainer>
        <Table width="100%">
          <TableBody>
            <TableRow>
              <TableCell>Location</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Time</TableCell>
              <TableCell>By</TableCell>
              <TableCell>Observation</TableCell>
              <TableCell>Correlation</TableCell>
              <TableCell>Zone</TableCell>
            </TableRow>
            {sightings.map((s) => (
              <TableRow key={s.id}>
                <TableCell>{s.location}</TableCell>
                <TableCell>{s.displaydate}</TableCell>
                <TableCell>{s.time ? s.time.replace(" ", "") : "-"}</TableCell>
                <TableCell>{s.sightedBy}</TableCell>
                <TableCell>{s.observation}</TableCell>
                <TableCell>{s.correlation ? s.correlation : "-"}</TableCell>
                <TableCell>{s.zone}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </SightingTableContainer>
    </>
  );
};

export default SightingHistory;

{
  /* <Table width="100%">
                        <TableHead>
                            <TableRow>
                                <TableHeaderColumn>Abbreviation</TableHeaderColumn>
                                <TableHeaderColumn>Denotation </TableHeaderColumn>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>K</TableCell>
                                <TableCell>Stands as the first letter of the national park name (Kumana)</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>L</TableCell>
                                <TableCell>Stands for Leopard</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>M or F</TableCell>
                                <TableCell>Reflects the Gender (Male/Female) of the Leopard</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>"1", "2", "3"....."47", "48", "49"....</TableCell>
                                <TableCell>Unique sequential number to exclusively identify individuals</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table> */
}
