import React from "react";
import styled from "styled-components";
import HamburgerMenuBar from "./HamburgerMenuBar";
import Logo from "./Logo";
import MenuItem from "./MenuItem";

interface INavBarProps {
  Justify?: string;
}

const NavBarContainer = styled.div<INavBarProps>`
  display: flex;
  width: 100%;
  justify-content: ${(props) => props.Justify || "space-between"};
  height: 40px;
`;

const LogoContainer = styled.div`
  flex: 1;
`;

const MenuContainer = styled.ul`
  color: #fff;
  text-decoration: none;
  display: flex;
  padding-right: 20px;

  @media (min-width: 1011px) {
    display: flex;
  }

  @media (max-width: 1010px) {
    display: none;
  }
`;

const HamburgerMenuContainer = styled.div`
  @media (min-width: 1011px) {
    visibility: hidden;
  }
  @media (max-width: max-width: 1010px) {
    visibility: visible;
  }
`;

interface INavBarProps {
  DisplayLogo?: boolean;
  Justify?: string;
}

const MainNavBar = ({ DisplayLogo, Justify }: INavBarProps) => {
  return (
    <NavBarContainer Justify={Justify}>
      {DisplayLogo && (
        <LogoContainer>
          <Logo />
        </LogoContainer>
      )}
      <MenuContainer>
        <MenuItem LinkTo="/kumana-national-park">Kumana National Park</MenuItem>
        <MenuItem LinkTo="/leopard-profiles">Leopard Profiles</MenuItem>
        <MenuItem LinkTo="/corelation-data">Co-relation Data</MenuItem>
        <MenuItem LinkTo="/gallery">Gallery</MenuItem>
        <MenuItem LinkTo="/about">About Us</MenuItem>
        <MenuItem LinkTo="/contribute">Contribute</MenuItem>
      </MenuContainer>
      <HamburgerMenuContainer>
        <HamburgerMenuBar />
      </HamburgerMenuContainer>
    </NavBarContainer>
  );
};

export default MainNavBar;
