import React from "react";
import styled from "styled-components";
import MainLayout from "../../components/layouts/MainLayout/MainLayout";
import Paragraph from "../../components/shared/Paragraph";
import ContributeForm from "./components/ContributeForm";


const BodyContainer = styled.div`
    margin: 20px;
`;


const Contribute = () => {
    return(
        <MainLayout Title="Become a contributor">
            <BodyContainer>
                 <Paragraph>
                Wildlife enthusiasts are warmly welcome to become contributors of this study. All information on leopard sightings/observation and photographic evidences will be treated with high regard and individual copyright credits will be maintained. 
            </Paragraph>
            <Paragraph>
                Feel free to upload your sighting data in our <a href="https://www.facebook.com/groups/399628524415941/">Facebook page</a>.
            </Paragraph>
             <ContributeForm />
            </BodyContainer>
        </MainLayout>
    );
};

export default Contribute;