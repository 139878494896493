import React from "react";
import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';
import "../../styles/image-gallery.css";

export interface GalleryImage {
    src: string;
    thumbnail?: string;
    alt?: string;
}

interface IImageGalleryProps {
    Images: GalleryImage[];
}

const ImageGallery = ({Images: images}: IImageGalleryProps) => {
    return(
        <>
            <Carousel images={images}
                objectFit='contain'
                isAutoPlaying={true}
                swipeThreshold={0.2}
                hasIndexBoard={false}
                hasMediaButton={false}
                hasCaptions="bottom"/>
        </>
    );
};

export default ImageGallery;
