import React from "react";
import styled from "styled-components";
import Paragraph from "../../../components/shared/Paragraph";
import Text from "../../../components/shared/Text";
import Title, { TitleType } from "../../../components/shared/Title";

const OverviewContainer = styled.div``;

const Overview = () => {
    return(
        <OverviewContainer>
            <Title Type={TitleType.MainTitle}>Overview</Title>
            <Paragraph>
                This initiative focuses on identifying individual leopards and recording their behavioral patterns for greater 
                conservation purposes. While observing behaviors of these elusive animals within Kumana and Kudumbigala boundaries, 
                we learned the need and importance of identifying individual leopards and continually recording their behavior patterns 
                for greater conservation purposes. Having north east boundary bordering to farming grounds and human settlements, 
                we observed the overlapping co-existence of Human-Leopard activities. Seeing regular leopard sightings within and outside 
                park boundaries, we initiated this work in identifying leopards and recording their behaviors in 2019. This work has 
                specifically helped park officials to get better understanding on the population density as well as to study the depth 
                of their individual territories.
            </Paragraph>
        </OverviewContainer>
    );
};

export default Overview;